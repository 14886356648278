const mobileMenuButton = document.getElementById('mobile-menu-button');
const mobileMenu = document.getElementById('mobile-menu');
const closeMenuButton = document.getElementById('close-menu-button');
const footer = document.getElementById('footer');

mobileMenuButton.addEventListener('click', () => {
    mobileMenu.classList.add('scale-100');
    // mobileMenu.classList.remove('hidden');
});

closeMenuButton.addEventListener('click', () => {
    mobileMenu.classList.remove('scale-100');
});


const generateFooter = () => {
    // generate current year and replace the footer text
    const currentYear = new Date().getFullYear();
    footer.innerHTML = footer.innerHTML.replace('$year', currentYear.toString());
}

generateFooter();

console.log('Hello World!');
